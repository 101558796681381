import React from "react"
import { Link, graphql} from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"


import UtilityStoryitem from '../components/utilityStoryitem.js'

/*import sanityClient from '@sanity/client'
import imageUrlBuilder from '@sanity/image-url'
// Get a pre-configured url-builder from your sanity client
const builder = imageUrlBuilder(sanityClient)

// Then we like to make a simple function like this that gives the
// builder an image and returns the builder for you to specify additional
// parameters:
function urlFor(source) {
  return builder.image(source)
}*/

// Conditional syntax in JSX
// https://stackoverflow.com/questions/53508119/what-is-the-syntax-for-a-simple-if-conditional-in-gatsby-js


export const query= graphql`
query {
   allTags: allSanityTag (sort: {fields: tag, order: ASC}) {
        totalCount
        edges {
          node {
            tag
            slug {
              current
            }
          }
        }
      }
      
    allOffers: allSanityOffer(filter:{isDeleted: {eq: false}}){
      edges{
        node{
          slug{current}
          tags{
            tag
            slug{current}
            }
        }
      }
    }
    
    bgImage:  file(relativePath: { eq: "home-01-1920x570.jpg" }) {
      childImageSharp {
        fixed(width: 1920, height: 570) {
          src
        }
      }
    }
    
    h1Image:  file(relativePath: { eq: "banner-arrow-184x147.png" }) {
      childImageSharp {
        fixed(width: 184, height: 147) {
          src
        }
      }
    }

    img1: file(relativePath: { eq: "page1_pic2-270x271.jpg" }) {
      childImageSharp {
        fixed(width: 270, height: 271) {
          src
        }
      }
    }
}
`

const CategoryPage = ({data}) => {

  let lastLetter = "x"
  let newLetter = ""
  let keepCol = true
  let currentCol = 0
  let tagsInUse = [];
  let tagData = [];

  data.allOffers.edges.forEach(itm => {
    itm.node.tags.forEach(itm2 => {
      tagsInUse.push(trimJunkChars(itm2.slug.current.trim()))
      tagData.push({'slug': trimJunkChars(itm2.slug.current).trim(),'tag': itm2.tag.trim()})
    })
  });

  //tagsInUse.sort()

  //https://stackoverflow.com/questions/1129216/sort-array-of-objects-by-string-property-value
  tagData.sort(compareTagObjects);

  //console.log(tagData);

  //const totalTags = data.allTags.totalCount
  const totalTags = tagsInUse.length

  let usedTags = [];


  return (
    <Layout>
      <SEO
        title="Oversikt over innhold på Gratis.no"
        description={"Alfabetisk liste med gratistilbud på gratis.no"}
        image={data.bgImage.childImageSharp.fixed}
      />

      <div>
        {/* Kategorier */}
        <section className="section novi-bg novi-bg-img section-lg bg-white section-top-shadow">
          <div className="container">
            <h2 style={{marginBottom:'40px'}} className="text-center text-sm-left">Kategorisert <span className="h2-style">innhold</span></h2>
            <div className="row">
              {/*ul className="categoryList col">
                {

                }
              data.allTags.edges.map(({ node: tag },i) => {
                newLetter = trimJunkChars(tag.tag).charAt(0)
                //console.log(newLetter + '== '+lastLetter + '??')
                //console.log(lastLetter)
                //console.log(i)
                if(keepCol && newLetter != lastLetter){
                  keepCol = (currentCol > totalTags/2?false:true)
                  currentCol = i
                }

                if(keepCol && !usedTags.includes(trimJunkChars(tag.slug.current).toLowerCase())){
                  return(
                    <li key={tag.slug.current}>{newLetter != lastLetter?<>{(lastLetter = newLetter) && <span className="newLetter">{trimJunkChars(tag.tag).charAt(0).toUpperCase()}</span>}<Link to={"/" + tag.slug.current+"/"}>{trimJunkChars(tag.tag).slice(1)}</Link></>:<Link to={"/" + tag.slug.current+"/"}>{tag.tag}</Link>}</li>
                  )
                }
              })
            </ul>*/}

              {/*<ul className="categoryList col">
              {data.allTags.edges.map(({ node: tag },i) => {
                newLetter = trimJunkChars(tag.tag).charAt(0)
                if(i>currentCol && !usedTags.includes(trimJunkChars(tag.slug.current).toLowerCase())){
                  return(
                    <li key={tag.slug.current}>{newLetter != lastLetter?<>{(lastLetter = newLetter) && <span className="newLetter">{trimJunkChars(tag.tag).charAt(0).toUpperCase()}</span>}<Link to={"/" + tag.slug.current+"/"}>{trimJunkChars(tag.tag).slice(1)}</Link></>:<Link to={"/" + tag.slug.current+"/"}>{tag.tag}</Link>}</li>
                  )
                }
              })}
            </ul>*/}

            <ul className="categoryList col">
              {tagData.map((tag,i) => {
                //console.log(tag);
                newLetter = trimJunkChars(tag.tag).charAt(0)
                if(i>currentCol && !usedTags.includes(trimJunkChars(tag.slug).toLowerCase())){
                  usedTags.push(tag.slug);
                  return(
                      <li key={i}>{newLetter != lastLetter?<>{(lastLetter = newLetter) && <span className="newLetter">{trimJunkChars(tag.tag).charAt(0).toUpperCase()}</span>}<Link to={"/" + tag.slug+"/"}>{trimJunkChars(tag.tag).slice(1)}</Link></>:<Link to={"/" + tag.slug+"/"}>{tag.tag}</Link>}</li>
                  )
                }
              })}
            </ul>

            </div>
          </div>
        </section>

      </div>
    </Layout>
  )
}

export default CategoryPage

function trimJunkChars(str){
  //console.log('first char: '+str.charAt(0)+ " ("+str+")");
  /*if(str.charAt(0) == " "){
    //console.log("AHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHH");
    str = str.substring(1);
  }*/

  str = (str.replace(/[^0-9a-zA-ZøæåÆØÅ-]/,"")).trim().replace(/\s/,'')
  return str
}

// https://stackoverflow.com/questions/25270864/what-is-last-num-refers-to-in-the-array-sort-function
function compareTagObjects( a, b ) {
  if ( a.tag < b.tag ){
    return -1;
  }
  if ( a.tag > b.tag ){
    return 1;
  }
  return 0;
}


